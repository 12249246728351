/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/dist/css/bootstrap.css';
@import '~font-awesome/css/font-awesome.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
// @import '~angular2-multiselect-dropdown/themes/default.theme.css';
@import '~leaflet/dist/leaflet.css';

:root {
  --primary-color: #E442BA;
  --secondary-color: #f7caed;
}

.btn-secondary {
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid var(--primary-color);
  border-radius: 0;
  color: var(--primary-color);
  font-weight: 600;
  text-shadow: none;
  padding-right: .7rem;
  padding-left: .7rem;
}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}

.background-lightgray {
  background: #f8f9fa !important;
}


.border-pink {
  border: 1px solid #E442BA !important;
}

.color-primary {
  color: #E442BA !important;
}

.btn-primary {
  background-color: #E442BA !important;
  border: 2px solid #E442BA !important;
}

.btn-outline-primary {
  background-color: white !important;
  border-color: #E442BA !important;
  color: #E442BA !important;
}

.btn-primary:hover {
  background-color: #FFC5F5;
  border-color: #FFC5F5;
}

.background-lightpink {
  background: #f7caed;
}

.color-lightpink {
  color: #f7caed;
}

.col-border-primary > .card {
  border: 3px solid var(--primary-color);
}

.categories {
  .pure-checkbox input[type=checkbox] + label:before {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .selected-list .c-list .c-token, .pure-checkbox input[type="checkbox"]:checked + label:before {
    background: var(--primary-color);
  }
}

.price {
  font-size: 23px;
  font-weight: bold;

  .decimal {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .dropdown-item.active, .dropdown-item:active {
    background-color: white;
    color: #212529;
  }
  .price {
    font-size: 5vw !important;
  }
}

.ngb-dp-day.disabled {
  color: lightgray;
}

.product-images .carousel-indicators {
  bottom: -40px;
  z-index: 1;

  li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--secondary-color);
  }

  li.active {
    background-color: var(--primary-color);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.alert-primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background: white;
}

app-vetrina-detail .accordion > .card > .card-header {
  background-color: white;
  border-bottom: 0!important;
}
app-vetrina-detail .accordion > .card > div > .card-body {
  padding-top: 0!important;
}
.panel-acquisti  {
  .card-header {
    background: white!important;
  }
  .card-body {
    padding: 0!important;
  }
}
.m-less {
  margin-left: -8px;
  margin-right: -8px;
}

.cursor-pointer {
  cursor: pointer;
}

.fa-star {
  color: #d2d2d2;

  &.yellow {
    color: #f9bb04;
  }
}
.icon-star {
  width: 16px;
  margin-top: -6px;
  &.large {
    width: 26px;
  }
}

.bg-info {
  background-color: #F9BB04 !important;
}

#panel-white > .card {
  border: none !important;

  .card-header {
    background: transparent !important;
    border: none !important;
    padding-left: 0!important;
    padding-right: 0!important;
  }
}
#panel-white > .card > .collapse > div.card-body {
  padding: 0!important;
}

.btn-info {
  background-color: #f7caed!important;
  color: var(--primary-color) !important;
  border:1px solid var(--primary-color) !important;
  width: 27px;
  height: 27px;
  font-size: 11px;
  i {
    color: white;
  }
}
.width-content .modal-content {
  width: min-content;
  @media (max-width: 768px) {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

.btn-tertiary {
  border: 1px solid #6e7072!important;
  border-radius: 0!important;
  text-align: left;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:focus, .btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none!important;
}

.carousel-sm-h {
  height: 520px;
  .carousel-inner {
    height: calc(100% - 1rem);
    width: auto;
    .carousel-item {
      height: 100%;
      width: auto!important;
    }
  }
}

.header-lightpink {
  .card-header {
    background: #f7caed!important;
  }
}
.text-black-50 {
  color: rgba(0,0,0,.5)!important;
}
.new-auction {
  .card-header {
    background: var(--secondary-color);
  }
  .card:not(.bg-success) .card-body {
    padding:0!important;
  }
}
app-new-auction ngb-datepicker {
  width: 100%;
  min-width: 330px!important;
  [ngbDatepickerDayView] {
    width: 100%;
  }
  .ngb-dp-month {
    width: 100%;
  }
  .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday {
    width: 15%;
  }
  .ngb-dp-navigation-chevron, .ngb-dp-weekday.small {
    color:var(--primary-color);
  }
  .btn-light.bg-primary.text-white {
    background-color: var(--primary-color) !important;
  }
}
.btn:focus, .btn.focus {
  box-shadow: none!important;
}

.loader-login {

    background: rgba(0, 0, 0, 0.6);
    padding-top: 20vh;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
